.WhoAmI {
	background: linear-gradient(55deg, #21D4FD 5%, #B721FF 95%);
	box-shadow: inset 0  .1em .4em -.15em #111,
							inset 0 -.1em .4em -.15em #111;
							//inset 0 -0.1em 0.2em -.01em rgba(1, 1, 1, 0.6);
	padding: 3em;
	color: white;
	
	> h1 {
		font-size: 3em;	
		margin-bottom: .5em;
		margin-top: 0;
	}
	
	> div {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			@media (max-width: 768px) {
				grid-template-columns: 1fr;
			}
			justify-items: center;
			align-items: start;
	}
}

.WhoAmIElement {
	font-size: 1.4em;
	padding: 0 .5em;
	@media (max-width: 768px) {
		padding: 2em 0;
	}

	> img {
		height: 8em;
		padding: .8em;
		border: .1em solid white;
		border-radius: 100%;
	}
	> h2 {
		margin: .1em;
	}
	> p {
		margin: .2em 0;
	}
}

.Projects {
	min-height: 30em;
	padding: .5em;
	
	> h1 {
		font-size: 3em;	
		margin: 1em 0;
	}
}

.Project {
	font-size: 1.4em;
	display: grid;
	grid-template-columns: 1fr 2.5fr;
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
	width: calc(100% - 4em);
	margin: 2em;
	text-align: left;
	position: relative;
	
	> picture {
		> img {
			width: 80%;
			margin: 0 10%;
			border-radius: 1em;
			filter: drop-shadow(.1em .1em .2em rgba(0,0,0,.5));
			overflow: hidden;
			transform: scale(.75);
		}

		> .zoom {
			transition: transform .5s, filter .5s;
			//cursor: pointer;
			transform: scale(1);
			
			&:hover {
				transform: scale(1.05);
				filter: drop-shadow(.2em .2em .6em rgba(0,0,0,.5));
			}	
		}
	}
	
	> div > h2 {
		margin-bottom: 0;
		margin-top: .5em;
	}
	
	> div > h3 {
		margin-top: 0;
	}
   
	> div> .github {
		position: absolute;
		top: .5em;
		right: .5em;
    overflow: hidden;
    border: 0;
		@media (max-width: 768px) {
			position: static;
			display: block;
			margin-bottom: .5em;
		}
	}
	
	> div > span {
		display: block;
		> ul {
			list-style: "- ";
			
			> li {
				margin: .5em 0;
			}
		}
	}
}

.ImageLinksContainer {
		display: grid;
		justify-items: center;
		align-items: center;
	
	> h2 {
		font-size: 2em;
		margin: 1em 5vw;
		@media (max-width: 768px) {
			margin: 1em 5vw;
		}
	}
	
	> div {
		display: grid;
		justify-items: center;
		align-items: center;
		grid-template-columns: repeat(2, 10em);
		grid-gap: 6em;
		margin: 0 1em;
		@media (max-width: 768px) {
			grid-gap: .5em;
			margin: 0;
		}
		
		> a {
			width: 100%;
			@media (max-width: 768px) {
				width: 80%;
			}
			
			> img {
				width: 100%;
			}
		}
	}
}

@import url(https://fonts.googleapis.com/css2?family=Poiret+One&family=Roboto:wght@300&display=swap);
body {
  margin: 0;
	font-family: 'Roboto', 'Segoe UI', 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
	box-sizing: border-box;
	font-weight: 300;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Header{display:grid;grid-template-columns:50% 50%;align-items:center;justify-items:center;height:30em;width:100%}@media (max-width: 768px){.Header{grid-template-columns:1fr;margin-bottom:4em}}.Header>span{padding:.5em;text-align:left;font-size:1.5em}.Header>span>h1{font-family:'Poiret One', cursive;font-weight:100;text-decoration:underline;text-decoration-thickness:from-font;margin:.1em 0}.Header>img{padding:5em;max-height:100%;max-width:100%;min-height:0;min-width:0;-webkit-filter:drop-shadow(0.1em 0.1em 0.2em #111);filter:drop-shadow(0.1em 0.1em 0.2em #111)}@media (max-width: 768px){.Header>img{grid-row:1}}

.language{position:fixed;right:0;top:0;margin-right:.25em;display:flex;flex-direction:row;border-radius:0 0 .5em .5em;-webkit-filter:drop-shadow(0.05em 0.05em 0.1em #111);filter:drop-shadow(0.05em 0.05em 0.1em #111);overflow:hidden;z-index:2}.language>div{cursor:pointer;padding:.5em;width:2em;color:black;background:white;transition:all .3s}.language>div:hover{color:white;background:black}

.contactMe{position:fixed;bottom:0;right:0;margin:.5em;padding:.5em;cursor:pointer;width:3em;background:white;border-radius:100%;-webkit-filter:drop-shadow(0.05em 0.05em 0.1em #111);filter:drop-shadow(0.05em 0.05em 0.1em #111);transition:-webkit-filter .5s;transition:filter .5s;transition:filter .5s, -webkit-filter .5s;z-index:2}.contactMe:hover{-webkit-filter:drop-shadow(0.075em 0.075em 0.2em #111);filter:drop-shadow(0.075em 0.075em 0.2em #111)}

.WhoAmI{background:linear-gradient(55deg, #21D4FD 5%, #B721FF 95%);box-shadow:inset 0  .1em .4em -.15em #111, inset 0 -.1em .4em -.15em #111;padding:3em;color:white}.WhoAmI>h1{font-size:3em;margin-bottom:.5em;margin-top:0}.WhoAmI>div{display:grid;grid-template-columns:repeat(3, 1fr);justify-items:center;align-items:start}@media (max-width: 768px){.WhoAmI>div{grid-template-columns:1fr}}.WhoAmIElement{font-size:1.4em;padding:0 .5em}@media (max-width: 768px){.WhoAmIElement{padding:2em 0}}.WhoAmIElement>img{height:8em;padding:.8em;border:.1em solid white;border-radius:100%}.WhoAmIElement>h2{margin:.1em}.WhoAmIElement>p{margin:.2em 0}

.Projects{min-height:30em;padding:.5em}.Projects>h1{font-size:3em;margin:1em 0}.Project{font-size:1.4em;display:grid;grid-template-columns:1fr 2.5fr;width:calc(100% - 4em);margin:2em;text-align:left;position:relative}@media (max-width: 768px){.Project{grid-template-columns:1fr}}.Project>picture>img{width:80%;margin:0 10%;border-radius:1em;-webkit-filter:drop-shadow(0.1em 0.1em 0.2em rgba(0,0,0,0.5));filter:drop-shadow(0.1em 0.1em 0.2em rgba(0,0,0,0.5));overflow:hidden;transform:scale(0.75)}.Project>picture>.zoom{transition:transform .5s, -webkit-filter .5s;transition:transform .5s, filter .5s;transition:transform .5s, filter .5s, -webkit-filter .5s;transform:scale(1)}.Project>picture>.zoom:hover{transform:scale(1.05);-webkit-filter:drop-shadow(0.2em 0.2em 0.6em rgba(0,0,0,0.5));filter:drop-shadow(0.2em 0.2em 0.6em rgba(0,0,0,0.5))}.Project>div>h2{margin-bottom:0;margin-top:.5em}.Project>div>h3{margin-top:0}.Project>div>.github{position:absolute;top:.5em;right:.5em;overflow:hidden;border:0}@media (max-width: 768px){.Project>div>.github{position:static;display:block;margin-bottom:.5em}}.Project>div>span{display:block}.Project>div>span>ul{list-style:"- "}.Project>div>span>ul>li{margin:.5em 0}.ImageLinksContainer{display:grid;justify-items:center;align-items:center}.ImageLinksContainer>h2{font-size:2em;margin:1em 5vw}@media (max-width: 768px){.ImageLinksContainer>h2{margin:1em 5vw}}.ImageLinksContainer>div{display:grid;justify-items:center;align-items:center;grid-template-columns:repeat(2, 10em);grid-gap:6em;margin:0 1em}@media (max-width: 768px){.ImageLinksContainer>div{grid-gap:.5em;margin:0}}.ImageLinksContainer>div>a{width:100%}@media (max-width: 768px){.ImageLinksContainer>div>a{width:80%}}.ImageLinksContainer>div>a>img{width:100%}

.Footer{background:linear-gradient(55deg, #21D4FD 5%, #B721FF 95%);box-shadow:inset 0  .1em .4em -.15em #111, inset 0 -.1em .4em -.15em #111;color:white;padding:min(3em, 5vw);margin:4em 0}.Footer>h1{font-size:3em;margin:.1em}.Footer>h2{font-size:2em}@media (max-width: 768px){.Footer>h2{font-size:1.5em}}.Footer>h2>a{color:white;text-decoration:none}.FooterImageLinksContainer{display:grid;justify-items:center;align-items:center;grid-template-columns:repeat(2, 1fr);grid-gap:6em;margin-top:4em}@media (max-width: 768px){.FooterImageLinksContainer{grid-template-columns:1fr;grid-gap:2em}}.FooterImageLinksContainer>a:first-child{justify-self:end}@media (max-width: 768px){.FooterImageLinksContainer>a:first-child{justify-self:center}}.FooterImageLinksContainer>a:nth-child(2){justify-self:start}@media (max-width: 768px){.FooterImageLinksContainer>a:nth-child(2){justify-self:center;transform:translateX(8%)}}.FooterImageLinksContainer>a>img{max-height:10em}


.Footer {
	background: linear-gradient(55deg, #21D4FD 5%, #B721FF 95%);
	box-shadow: inset 0  .1em .4em -.15em #111,
							inset 0 -.1em .4em -.15em #111;
							//inset 0 -0.1em 0.2em -.01em rgba(1, 1, 1, 0.6);
	color: white;
	padding: #{"min(3em, 5vw)"};
	margin: 4em 0;
	
	> h1 {
		font-size: 3em;	
		margin: .1em;
	}

	> h2 {
		font-size: 2em;
		@media (max-width: 768px) {
			font-size: 1.5em;
		}
		> a {
			color: white;
			text-decoration: none;
		}
	}
}


.FooterImageLinksContainer {
	display: grid;
	justify-items: center;
	align-items: center;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 6em;
	margin-top: 4em;
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		grid-gap: 2em;
	}

	> a:first-child {
		justify-self: end;
		@media (max-width: 768px) {
			justify-self: center;
		}
	}
	> a:nth-child(2) {
		justify-self: start;
		@media (max-width: 768px) {
			justify-self: center;
			transform: translateX(8%);
		}
	}

	> a > img {
		max-height: 10em;
	}
}

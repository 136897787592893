.language {
	position: fixed;
	right: 0;
	top: 0;
	margin-right: .25em;
	display: flex;
	flex-direction: row;
	border-radius: 0 0 .5em .5em;
	filter: drop-shadow(.05em .05em .1em #111);
	overflow: hidden;
	z-index: 2;
	
	> div {
		cursor: pointer;
		padding: .5em;
		width: 2em;
		color: black;
		background: white;
		transition: all .3s;
		
		&:hover {
			color: white;
			background: black;
		}
	}
}

.Header {
		display: grid;
		grid-template-columns: 50% 50%;
		@media (max-width: 768px) {
			grid-template-columns: 1fr;
			margin-bottom: 4em;
		}
		align-items: center;
		justify-items: center;
		height: 30em;
		width: 100%;
		
		> span {
			padding: .5em;
			text-align: left;
			font-size: 1.5em;
			
			> h1 {
				font-family: 'Poiret One', cursive;
				font-weight: 100;
				text-decoration: underline;
				text-decoration-thickness: from-font;
				margin: .1em 0;
			}
		}
		
		> img {
			@media (max-width: 768px) {
				grid-row: 1;
			}
			padding: 5em;
			max-height: 100%;
			max-width: 100%;
			min-height: 0;
			min-width: 0;
			filter: drop-shadow(.1em .1em .2em #111);
		}
}

.contactMe {
	position: fixed;
	bottom: 0;
	right: 0;
	margin: .5em;
	padding: .5em;
	cursor: pointer;
	width: 3em;
	background: white;
	border-radius: 100%;
	filter: drop-shadow(.05em .05em .1em #111);
	transition: filter .5s;
	z-index: 2;

	&:hover {
		filter: drop-shadow(.075em .075em .2em #111);
	}
}